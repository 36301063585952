import { ICourse, ILesson, IModule, IQuiz, ICriteria } from "@types";

interface ICourseState {
  currentCourse: ICourse | null;
  currentModule: IModule | null;
  currentModuleList: IModule[];
  currentLesson: ILesson | null;
  currentQuiz: IQuiz | null;
  createLessonModalOpen: boolean;
  quizSettingsModalOpen: boolean;
  selectedModuleId: number | null;
  currentTask: ICriteria | null;
}

export const CourseState: ICourseState = {
  currentCourse: null,
  currentModule: null,
  currentModuleList: [],
  currentLesson: null,
  currentQuiz: null,
  createLessonModalOpen: false,
  quizSettingsModalOpen: false,
  selectedModuleId: null,
  currentTask: null,
};
