import { ICriteria } from "./../../@types/domain";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TTestStatus } from "@types";
import { initialTest, LessonState } from "./state";
import {
  completeLessonThunk,
  getLessonDetailThunk,
  getParticipantCourseModuleListThunk,
  sendTestAnswersThunk,
} from "./thunk";

const lessonSlice = createSlice({
  name: "lesson",
  initialState: LessonState,
  reducers: {
    setTestState(state, action: PayloadAction<TTestStatus>) {
      state.test.state = action.payload;
    },
    setAnswer(
      state,
      action: PayloadAction<{ questionIndex: number; variantIndex: number }>
    ) {
      const { questionIndex, variantIndex } = action.payload;
      const variant =
        state.test.questions[questionIndex].variants[variantIndex];
      state.test.questions[questionIndex].variants[variantIndex].choice =
        !variant.choice;
    },
    clearTest(state) {
      state.test = initialTest;
    },
    resetTest(state, action: PayloadAction<ICriteria[]>) {
      state.test.questions = action.payload;
      state.test.state = "not_started";
    },
  },
  extraReducers(builder) {
    builder.addCase(
      getParticipantCourseModuleListThunk.fulfilled,
      (state, action) => {
        state.participantModules = action.payload;
      }
    );
    builder.addCase(getLessonDetailThunk.fulfilled, (state, action) => {
      const lesson = action.payload;
      state.currentLesson = lesson;

      if (lesson?.quiz && lesson?.quiz?.type === "default") {
        state.test.quizId = lesson.quiz.id;
        state.test.questions = lesson.quiz.criterion;
      }
    });
    builder.addCase(completeLessonThunk.fulfilled, (state, action) => {
      if (action.payload === undefined) return;

      const { id, module } = action.payload;

      const moduleIndex = state.participantModules.findIndex(
        (m) => m.id === module
      );
      if (moduleIndex === -1) return;

      const lessonIndex = state.participantModules[
        moduleIndex
      ].lessons.findIndex((l) => l.id === id);
      if (lessonIndex === -1) return;

      state.participantModules[moduleIndex].lessons[lessonIndex].is_passed =
        true;
    });
    builder.addCase(sendTestAnswersThunk.fulfilled, (state, action) => {
      state.test.sendAnswersRequesting = false;
    });
    builder.addCase(sendTestAnswersThunk.pending, (state) => {
      state.test.sendAnswersRequesting = true;
    });
    builder.addCase(sendTestAnswersThunk.rejected, (state, action) => {
      state.test.sendAnswersRequesting = true;
    });
  },
});

export const { setTestState, setAnswer, clearTest, resetTest } =
  lessonSlice.actions;

export default lessonSlice;
