import { ICreateQuizAPIParams, IUpdateQuizAPIParams } from "@types";
import httpClient from "services/http.service";

export const createQuizAPI = async (data: ICreateQuizAPIParams) => {
  return await httpClient.post("quizzes/", data);
};

export const updateQuizAPI = async (body: IUpdateQuizAPIParams) => {
  const { quizId, data } = body;

  const res = await httpClient.patch(`quizzes/${quizId}/`, data);
  return res.data;
};
