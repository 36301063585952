import { TLocale } from "@types";

export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

export const DEFAULT_LOCALE: TLocale = "ru";

export const SITE_NAME = "Courses JVA";

export const quizSettingKeys = [
  // "enable_timer",
  "limit_attempts",
  "random_questions_order",
  "shuffle_answer_variants",
  "show_correct_answer_variants",
  "must_pass_successfully",
] as const;

function makePlaceholderURL(width: string, height: string) {
  const bgColor = "#42C87A";
  const textColor = "#FFFFFF";

  const imagePlaceholderURL = new URL("https://images.placeholders.dev");

  const url = new URL(imagePlaceholderURL);
  url.searchParams.set("width", width);
  url.searchParams.set("height", height);
  url.searchParams.set("text", "Courses JVA");
  url.searchParams.set("bgColor", bgColor);
  url.searchParams.set("textColor", textColor);

  return url.toString();
}

export const defaultCoverPlaceholderURL = makePlaceholderURL("640", "480");
export const smallCoverPlaceholderURL = makePlaceholderURL("160", "90");
export const extraSmallCoverPlaceholderURL = makePlaceholderURL("104", "104");

export const userAgreementURL =
  "https://docs.google.com/document/d/e/2PACX-1vRoC3lLL2A3HZcPvGWrUgIPiFMsxQtdzSALpxJjx320m1QQXMFcoQ16BCUl_pqZPa6IL5nRA3plnzx_/pub";
export const privacyPolicyURL =
  "https://docs.google.com/document/d/e/2PACX-1vSW2cEhAMs-oz-iyCEU6dXr6SIxpnw2TFh-4QYtKWjdFUQa61_Ol3KCgIFSQSdmcfEUXL80ZzyPGa7H/pub";
