import { createAsyncThunk } from "@reduxjs/toolkit";
import { TEditProfileForm } from "@types";
import { getUserDataAPI } from "api/auth.api";
import { updateProfileAPI } from "api/profile.api";

export const getUserDataThunk = createAsyncThunk(
  "auth/getUserData",
  async () => {
    const res = await getUserDataAPI();
    return res.data;
  }
);

export const updateProfileThunk = createAsyncThunk(
  "auth/updateProfile",
  async (data: TEditProfileForm) => {
    const res = await updateProfileAPI(data);
    return res.data;
  }
);
