import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICriteria, ILesson } from "@types";
import { CourseState } from "./state";
import {
  copyCriteriaThunk,
  createCriteriaThunk,
  createLessonThunk,
  createModuleThunk,
  deleteCriteriaThunk,
  deleteLessonThunk,
  deleteModuleThunk,
  getCourseDetailThunk,
  getLessonDetailThunk,
  updateCourseThunk,
  updateCriteriaThunk,
  updateLessonThunk,
  updateModuleThunk,
} from "./thunk";

const courseSlice = createSlice({
  name: "course",
  initialState: CourseState,
  reducers: {
    setCreateLessonModalOpen: (state, action: PayloadAction<boolean>) => {
      state.createLessonModalOpen = action.payload;
    },
    setSelectedModuleId: (state, action: PayloadAction<number>) => {
      state.selectedModuleId = action.payload;
    },
    setQuizSettingsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.quizSettingsModalOpen = action.payload;
    },
  },
  extraReducers(builder) {
    // course
    builder.addCase(getCourseDetailThunk.fulfilled, (state, action) => {
      const course = action.payload;
      state.currentCourse = course;
      state.currentModuleList = course.modules;
    });
    builder.addCase(updateCourseThunk.fulfilled, (state, action) => {
      state.currentCourse = action.payload;
    });
    // module
    builder.addCase(createModuleThunk.fulfilled, (state, action) => {
      state.currentModuleList?.push(action.payload);
    });
    builder.addCase(deleteModuleThunk.fulfilled, (state, action) => {
      const filteredModules = state.currentCourse?.modules?.filter(
        (m) => m.id !== action.payload
      );

      if (filteredModules && state.currentCourse?.modules) {
        state.currentModuleList = filteredModules;
      }
    });
    builder.addCase(updateModuleThunk.fulfilled, (state, action) => {
      const _module = action.payload;
      const moduleIndex = state.currentCourse?.modules?.findIndex(
        (m) => m.id === _module.id
      );

      if (moduleIndex === -1) return;

      state.currentModuleList.splice(moduleIndex!, 1, _module);
    });
    // lesson
    builder.addCase(
      getLessonDetailThunk.fulfilled,
      (state, action: PayloadAction<ILesson>) => {
        const lesson = action.payload;
        state.currentLesson = lesson;
        state.currentQuiz = lesson.quiz;

        if (lesson?.quiz?.criterion?.find((c) => c.type === "mentor")) {
          state.currentTask = lesson?.quiz?.criterion?.at(0) || null;
        }
      }
    );
    builder.addCase(
      createLessonThunk.fulfilled,
      (state, action: PayloadAction<ILesson>) => {
        const lesson = action.payload;
        const moduleIndex = state.currentCourse?.modules.findIndex(
          (m) => m.id === lesson.module
        );

        if (moduleIndex === -1) return;

        state.currentCourse?.modules[moduleIndex!].lessons.push(lesson);
      }
    );
    builder.addCase(updateLessonThunk.fulfilled, (state, action) => {
      const lesson = action.payload;
      const moduleIndex = state.currentCourse?.modules?.findIndex(
        (m) => m.id === lesson.module
      );

      if (moduleIndex === -1) return;

      const lessonIndex = state.currentCourse?.modules[
        moduleIndex!
      ].lessons.findIndex((l) => l.id === lesson.id);

      if (lessonIndex === -1) return;

      state.currentCourse?.modules[moduleIndex!].lessons.splice(
        lessonIndex!,
        1,
        lesson
      );

      state.currentModuleList[moduleIndex!].lessons.splice(
        lessonIndex!,
        1,
        lesson
      );
    });
    builder.addCase(deleteLessonThunk.fulfilled, (state, action) => {
      const { moduleId, lessonId } = action.payload;
      const moduleIndex = state.currentCourse?.modules?.findIndex(
        (m) => m.id === moduleId
      );

      if (moduleIndex === -1) return;

      const filteredLessons = state.currentCourse?.modules[
        moduleIndex!
      ]?.lessons?.filter((l) => l.id !== lessonId);

      if (
        filteredLessons &&
        state.currentCourse?.modules[moduleIndex!]?.lessons
      ) {
        state.currentCourse.modules[moduleIndex!].lessons = filteredLessons;
      }
    });
    // question/criteria
    builder.addCase(
      createCriteriaThunk.fulfilled,
      (state, action: PayloadAction<ICriteria>) => {
        const criteria = action.payload;
        if (criteria.type === "mentor") return;
        state.currentQuiz?.criterion?.push(action.payload);
      }
    );
    builder.addCase(
      updateCriteriaThunk.fulfilled,
      (state, action: PayloadAction<ICriteria>) => {
        const criteria = action.payload;
        const criteriaIndex = state.currentQuiz?.criterion?.findIndex(
          (c) => c.id === criteria.id
        );

        if (criteriaIndex === -1) return;

        state.currentQuiz?.criterion?.splice(criteriaIndex!, 1, criteria);
      }
    );
    builder.addCase(deleteCriteriaThunk.fulfilled, (state, action) => {
      if (state.currentQuiz?.criterion) {
        state.currentQuiz.criterion = state.currentQuiz?.criterion?.filter(
          (c) => c.id !== action.payload
        );
      }
    });
    builder.addCase(
      copyCriteriaThunk.fulfilled,
      (
        state,
        action: PayloadAction<{
          originalCriteriaId: number;
          newCriteria: ICriteria;
        }>
      ) => {
        const { originalCriteriaId, newCriteria } = action.payload;
        const criteriaIndex = state.currentQuiz?.criterion?.findIndex(
          (c) => c.id === originalCriteriaId
        );

        if (criteriaIndex === -1) return;

        state.currentQuiz?.criterion?.splice(criteriaIndex!, 0, newCriteria);
      }
    );
  },
});

export const {
  setCreateLessonModalOpen,
  setSelectedModuleId,
  setQuizSettingsModalOpen,
} = courseSlice.actions;

export default courseSlice;
