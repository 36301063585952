import { ICriteria, IModule, IParticipantLesson, TTestStatus } from "@types";

interface ITestState {
  quizId: number | null;
  questions: ICriteria[];
  state: TTestStatus;
  sendAnswersRequesting: boolean;
}

export const initialTest: ITestState = {
  quizId: null,
  questions: [],
  state: "not_started",
  sendAnswersRequesting: false,
};

interface ILessonState {
  participantModules: IModule[];
  currentLesson: IParticipantLesson | null;
  test: ITestState;
}

export const LessonState: ILessonState = {
  participantModules: [],
  currentLesson: null,
  test: initialTest,
};
