import { copyCriteriaAPI } from "./../../api/criteria.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICreateLessonAPIParams,
  ICreateModuleAPIParams,
  IDeleteLessonAPIParams,
  IDeleteModuleAPIParams,
  IUpdateCourseAPIParams,
  IUpdateLessonAPIParams,
  IUpdateModuleAPIParams,
  TCreateCourseAPIParams,
  IUpdateQuizAPIParams,
  ICreateQuizAPIParams,
  IGetLessonDetailAPIParams,
  ICreateCriteriaAPIParams,
  IUpdateCriteriaAPIParams,
} from "@types";
import {
  createCourseAPI,
  getCourseDetailAPI,
  updateCourseAPI,
} from "api/course.api";
import {
  createLessonAPI,
  deleteLessonAPI,
  getLessonDetailAPI,
  updateLessonAPI,
} from "api/lesson.api";
import {
  createModuleAPI,
  deleteModuleAPI,
  updateModuleAPI,
} from "api/module.api";
import { createQuizAPI, updateQuizAPI } from "api/quiz.api";
import {
  createCriteriaAPI,
  deleteCriteriaAPI,
  updateCriteriaAPI,
} from "api/criteria.api";

export const createCourseThunk = createAsyncThunk(
  "course/createCourse",
  async (data: TCreateCourseAPIParams, thunkAPI) => {
    try {
      const res = await createCourseAPI(data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const updateCourseThunk = createAsyncThunk(
  "course/updateCourse",
  async (data: IUpdateCourseAPIParams, thunkAPI) => {
    try {
      const res = await updateCourseAPI(data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getCourseDetailThunk = createAsyncThunk(
  "course/getCourseDetail",
  async (id: number) => {
    return await getCourseDetailAPI(id);
  }
);

export const createModuleThunk = createAsyncThunk(
  "course/createModule",
  async (data: ICreateModuleAPIParams) => {
    const res = await createModuleAPI(data);
    return res.data;
  }
);

export const updateModuleThunk = createAsyncThunk(
  "course/updateModule",
  async (body: IUpdateModuleAPIParams) => {
    const res = await updateModuleAPI(body);
    return res.data;
  }
);

export const deleteModuleThunk = createAsyncThunk(
  "course/deleteModule",
  async (data: IDeleteModuleAPIParams) => {
    await deleteModuleAPI(data);
    return data.moduleId;
  }
);

export const createLessonThunk = createAsyncThunk(
  "course/createLesson",
  async (data: ICreateLessonAPIParams, thunkAPI) => {
    try {
      const res = await createLessonAPI(data);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getLessonDetailThunk = createAsyncThunk(
  "course/getLessonDetail",
  async (body: IGetLessonDetailAPIParams) => {
    const res = await getLessonDetailAPI(body);
    return res.data;
  }
);

export const updateLessonThunk = createAsyncThunk(
  "course/updateLesson",
  async (body: IUpdateLessonAPIParams) => {
    const res = await updateLessonAPI(body);
    return res.data;
  }
);

export const deleteLessonThunk = createAsyncThunk(
  "course/deleteLesson",
  async (data: IDeleteLessonAPIParams) => {
    await deleteLessonAPI(data);
    return data;
  }
);

export const createQuizThunk = createAsyncThunk(
  "course/createQuiz",
  async (data: ICreateQuizAPIParams) => {
    const res = await createQuizAPI(data);
    return res.data;
  }
);

export const updateQuizThunk = createAsyncThunk(
  "course/updateQuiz",
  async (data: IUpdateQuizAPIParams) => {
    const res = await updateQuizAPI(data);
    return res.data;
  }
);

export const createCriteriaThunk = createAsyncThunk(
  "course/createCriteria",
  async (data: ICreateCriteriaAPIParams) => {
    const res = await createCriteriaAPI(data);
    return res.data;
  }
);

export const updateCriteriaThunk = createAsyncThunk(
  "course/updateCriteria",
  async (data: IUpdateCriteriaAPIParams) => {
    const res = await updateCriteriaAPI(data);
    return res.data;
  }
);

export const deleteCriteriaThunk = createAsyncThunk(
  "course/deleteCriteria",
  async (criteriaId: number) => {
    await deleteCriteriaAPI(criteriaId);
    return criteriaId;
  }
);

export const copyCriteriaThunk = createAsyncThunk(
  "course/copyCriteria",
  async (criteriaId: number) => {
    const res = await copyCriteriaAPI(criteriaId);
    return { originalCriteriaId: criteriaId, newCriteria: res.data };
  }
);
