import { completeLessonAPI, sendTestAnswersAPI } from "api/participant.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IGetLessonDetailAPIParams,
  ISendTestAnswersAPIParams,
  ITestResults,
} from "@types";
import { getLessonDetailAPI } from "api/lesson.api";
import { getModuleListAPI } from "api/module.api";

export const getParticipantCourseModuleListThunk = createAsyncThunk(
  "lesson/getParticipantCourseModuleList",
  async (courseId: number, thunkAPI) => {
    try {
      const res = await getModuleListAPI(courseId);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getLessonDetailThunk = createAsyncThunk(
  "lesson/getLessonDetail",
  async (params: IGetLessonDetailAPIParams, thunkAPI) => {
    try {
      const res = await getLessonDetailAPI(params);
      return res.data;
    } catch (e: any) {
      if (e?.response) {
        return thunkAPI.rejectWithValue(e?.response);
      }
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const completeLessonThunk = createAsyncThunk(
  "lesson/completeLesson",
  async (params: IGetLessonDetailAPIParams, thunkAPI) => {
    try {
      const res = await completeLessonAPI(params);
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const sendTestAnswersThunk = createAsyncThunk<
  ITestResults,
  ISendTestAnswersAPIParams
>("lesson/sendTestAnswers", async (data, thunkAPI) => {
  try {
    const res = await sendTestAnswersAPI(data);
    return res.data;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
