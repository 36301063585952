import { ICreateCriteriaAPIParams, IUpdateCriteriaAPIParams } from "@types";
import httpClient from "services/http.service";

export const createCriteriaAPI = async (data: ICreateCriteriaAPIParams) => {
  return await httpClient.post("quiz-criteria/", data);
};

export const updateCriteriaAPI = async (body: IUpdateCriteriaAPIParams) => {
  const { criteriaId, data } = body;
  return await httpClient.patch(`quiz-criteria/${criteriaId}/`, data);
};

export const deleteCriteriaAPI = async (criteriaId: number) => {
  await httpClient.delete(`quiz-criteria/${criteriaId}/`);
};

export const copyCriteriaAPI = async (criteriaId: number) => {
  return await httpClient.post(`quiz-criteria/${criteriaId}/copy/`);
};
